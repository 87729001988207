import { useFetcher, useMatches } from "@remix-run/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useCurrentRecipe } from "~/utils/data/useRecipeRouteData";
import { useCookRecipeSelectorContext } from "./cookRecipeContext";

const RecipeContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useRecipeContextVals = () => {
  const [adjustedServings, setAdjustedServings] = useState(0);
  const recipeFetcher = useFetcher();
  const dataFetcher = useFetcher();
  const recipes = useCookRecipeSelectorContext((state) => state.recipes);
  const recipe = useCookRecipeSelectorContext((state) => state.recipe);

  const recipeContext = useCurrentRecipe();

  const currentRecipe = useMemo(() => {
    const currentRecipeId = recipe?.id ?? recipeContext?.id;
    const foundRecipe = recipes.find((r) => r.id === currentRecipeId);
    if (foundRecipe && recipeContext) {
      return { ...foundRecipe, ...recipeContext };
    } else if (foundRecipe) {
      return foundRecipe;
    } else {
      return recipeContext;
    }
  }, [recipes, recipe, recipeContext]);

  useEffect(() => {
    if (recipe.id) {
      // need to reset the adjusted servings
      setAdjustedServings(parseInt(recipe?.recipeYield?.split(" ")[0]));
    }
  }, [recipe?.id]);
  const [forked, setForked] = useState(currentRecipe?.forked);
  const [ratio, setRatio] = useState(1);
  const notesFetcher = useFetcher();

  const matches = useMatches();

  const onAppTenant = useMemo(() => {
    const paths: string[] = ["routes/app.$tenant"];
    return matches.find((f) => paths.includes(f.id.toLowerCase()));
  }, [matches]);

  const recipeRoute = useMemo(() => {
    const appTenantRoute = onAppTenant;
    if (appTenantRoute) {
      return matches?.find((match) => match.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
    } else {
      return matches?.find((match) => match.id === "routes/recipe/$urlId.$urlKey");
    }
  }, [matches, onAppTenant, currentRecipe]);

  const currentRecipeNew = useMemo(() => {
    return recipeRoute?.data?.item;
  }, [recipeRoute]);

  const recipeData = useMemo(() => {
    return dataFetcher.data ?? currentRecipe;
  }, [dataFetcher.data, currentRecipe]);

  const originalServings = useMemo(() => {
    return currentRecipe?.recipeYield?.split(" ")[0] ?? 1;
  }, [currentRecipe?.recipeYield]);

  const changeServings = useCallback((newServings) => {
    // if newServings is NaN, set to 1
    if (isNaN(newServings)) {
      newServings = 1;
    }

    setAdjustedServings(parseInt(newServings));
  }, []);

  useEffect(() => {
    // calculate ratio
    const ratio = parseInt(adjustedServings) / parseInt(originalServings);
    // if ratio is NaN, set to 1
    if (isNaN(ratio)) {
      setRatio(1);
    } else {
      setRatio(ratio);
    }
    // setAdjustedServings(adjustedServings);
  }, [adjustedServings, originalServings]);

  useEffect(() => {
    setForked(currentRecipe?.forked);
  }, [currentRecipe?.forked]);

  return {
    adjustedServings,
    recipeFetcher,
    dataFetcher,
    forked,
    setForked,
    changeServings,
    ratio,
    recipeData,
    notesFetcher,
  };
};

const RecipeContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const servingsContext = useRecipeContextVals(props);

  return <RecipeContext.Provider value={{ ...servingsContext }}>{props.children}</RecipeContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useRecipeContext = () => useContext(RecipeContext);
const useRecipeSelectorContext = (selector: any) => {
  return useContextSelector(RecipeContext, selector);
};
export { RecipeContextProvider, useRecipeContext, useRecipeSelectorContext };
